<!-- @Author: Yu_Bo -->
<template>
  <div class='home_main'>
    首页
    <!-- 分页 -->
    <paging-page :total='100' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>

    <point-out ref='dialogTip' dialogTitle="温馨提示" dialogWidth='420px' :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='false' :affirmShow='true'>
      <div class="zc_dialog_tip_content">
        <div class="text">请先下载PC客户端，完成安装后再体验，客户端目前仅支持windows版本哦～</div>
      </div>
    </point-out>
    <trial-expiration ref='dialogTrial' dialogWidth='420px' borderRadius='10px' :cancelShow='false' :affirmShow='true'>
      <div class="zc_dialog_trial_content">
        <img class="img" src="@/assets/images/no_html/no_img_a.png" alt="">
        <div class="text">您的试用已过期</div>
        <div class="tip">如果需要继续使用，请联系我们申请正式使用</div>
      </div>
    </trial-expiration>
    <no-draw-times ref='dialogDraw'></no-draw-times>
    <contact-service ref='dialogService'></contact-service>
    <pay-page ref='dialogPay'></pay-page>
    <pay-diamond ref='dialogdiamond'></pay-diamond>
    <invest-money ref='dialogInv'></invest-money>
    <account-balance ref='dialogAcc'></account-balance>
    <open-member ref='dialogMember'></open-member>
    <membership-interests ref='dialogInterests'></membership-interests>
    <details-model ref='dialogModel'></details-model>
    <details-fenshen ref='dialogFenshen'></details-fenshen>
    <empty-data img='no_img_b.png' text='暂无数据'></empty-data>

    <i class="el-icon-edit"></i>
    <i class="el-icon-share"></i>
    <i class="el-icon-delete"></i>
    <el-button class="btnBgColor_blue" type="primary" icon="el-icon-search" @click="loginBtn">搜索</el-button>
    <div v-for="(item,index) in 100" :key="index">{{item}}</div>
  </div>
</template>

<script>
  import PointOut from '@/components/point_out/point_out.vue'//温馨提示弹框
  import TrialExpiration from '@/components/trial_expiration/trial_expiration.vue'//试用过期
  import NoDrawTimes from '@/components/trial_expiration/no_draw_times.vue'//没有绘画次数
  import ContactService from '@/components/contact_service/contact_service.vue'//联系客服
  import PayPage from '@/components/pay_page/pay_page.vue'//支付
  import PayDiamond from '@/components/pay_page/pay_diamond.vue'//支付
  import InvestMoney from '@/components/invest_money/invest_money.vue'//充值
  import OpenMember from '@/components/invest_money/open_member.vue'//开通会员
  import AccountBalance from '@/components/account_balance/account_balance.vue'//账户余额
  import MembershipInterests from '@/components/membership_interests/membership_interests.vue'//会员权益
  import DetailsModel from '@/components/details_popover/details_model.vue'//模型详情
  import DetailsFenshen from '@/components/details_popover/details_fenshen.vue'//分身详情
  import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
  import PagingPage from '@/components/paging_page/paging_page.vue'//分页

  export default {
    components: {
      PointOut,
      TrialExpiration,
      NoDrawTimes,
      ContactService,
      PayPage,
      PayDiamond,
      InvestMoney,
      AccountBalance,
      OpenMember,
      MembershipInterests,
      DetailsModel,
      DetailsFenshen,
      EmptyData,
      PagingPage,
    },
    props: {},
    data() {
      return {
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
      loginBtn() {
        this.$router.push('/workben/index');
      },
      sizeChangeBtn(val){
        console.log(val)
      },
      currentChangeBtn(val){
        console.log(val)
      }
    },
  }
</script>

<style lang='scss' scoped>
  .home_main {

  }
</style>
