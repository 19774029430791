<!-- @Author: Yu_Bo -->
<template>
	<div class='contact_service zc_dialog_box'>
    <el-dialog :visible.sync="dialogVisible" width="640px" :close-on-click-modal='false'>
      <div class="title" slot="title">
        <div class="name">联系客服</div>
      </div>
      <div class="service_box">
        <div class="box_left">
          <div class="img">
            <img src="@/assets/images/img/service_img.png" alt="">
          </div>
          <div class="text">
            <span>请使用微信扫码</span>
            <span>添加客服微信</span>
          </div>
        </div>
        <div class="box_right">
          <div class="r_tip">您也可以留下联系方式，我们将尽快与您联系</div>
          <div class="r_input">
            <div class="i_left"><span>*</span>姓名</div>
            <div class="i_right">
              <el-input size='small' v-model="user_name" placeholder="请输入姓名"></el-input>
            </div>
          </div>
          <div class="r_input">
            <div class="i_left"><span>*</span>公司名称</div>
            <div class="i_right">
              <el-input size='small' v-model="corporate_name" placeholder="请输入公司名称"></el-input>
            </div>
          </div>
          <div class="r_input">
            <div class="i_left"><span>*</span>企业邮箱</div>
            <div class="i_right">
              <el-input size='small' v-model="enterprise_mailbox" placeholder="请输入企业邮箱"></el-input>
            </div>
          </div>
          <div class="r_input">
            <div class="i_left"><span>*</span>电话号码</div>
            <div class="i_right">
              <el-input size='small' v-model="telephone_number" placeholder="请输入电话号码"></el-input>
            </div>
          </div>
          <div class="r_input">
            <div class="i_left"><span>*</span>备注</div>
            <div class="i_right_text">
              <el-input type="textarea" v-model="remarks" :rows="4" resize='none' placeholder="请输入备注" maxlength="2000" show-word-limit></el-input>
            </div>
          </div>
          <div class="right_btn">
            <el-button class="btnBgColor_grey" size="small" @click="dialogVisible = false">取 消</el-button>
            <el-button class="btnBgColor_blue" size="small" type="primary" @click="dialogVisible = false">保存</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {},
		data() {
			return{
        dialogVisible:false,
        user_name:'',
        corporate_name:'',
        enterprise_mailbox:'',
        telephone_number:'',
        remarks:'',
			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 打开弹框
      openDialogBtn(){
        this.dialogVisible=true
      },
    },
	}
</script>

<style lang='scss' scoped>
	.contact_service{
    .service_box{
      width: 100%;
      height: 382px;
      display: flex;
      justify-content: space-between;
      .box_left{
        width: 200px;
        height: 100%;
        background: url(~@/assets/images/background/service_background.jpg) no-repeat 100% 100%;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .img{
          width: 142px;
          height: 142px;
          padding: 6px;
          background: rgba(255,255,255,0.52);
          border-radius: 10px;
          border: 1px solid #F5FAFF;
          img{
            display: block;
            width: 100%;
            height: 100%;
          }
        }
        .text{
          text-align: center;
          padding-top: 40px;
          span{
            display: block;
            line-height: 22px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
          }
        }
      }
      .box_right{
        width: 380px;
        height: 100%;
        .r_tip{
          width: 100%;
          padding-bottom: 4px;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
        .r_input{
          width: 100%;
          padding-top: 16px;
          display: flex;
          justify-content: space-between;
          .i_left{
            width: 80px;
            line-height: 32px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: $bColor3;
            span{
              font-weight: bold;
              padding-right: 5px;
              color: $redColor2;
            }
          }
          .i_right,.i_right_text{
            width: 300px;
            .el-input{
              width: 100%;
            }
            .el-textarea{
              width: 100%;
            }
          }
        }
        .right_btn{
          width: 100%;
          padding-top: 20px;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
	}
</style>
